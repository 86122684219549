<template>
  <Layout>
    <PageHeader :title="title" />

    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <router-link
              to="/create-new-employee"
              class="btn btn-success  btn-sm"
              data-toggle="modal"
              data-target="#ServicesModal"
            >
              Add New Employee
            </router-link>

            <div class="row mt-4">
              <div class="col-sm-12 col-md-6">
                <div id="tickets-table_length" class="dataTables_length">
                  <label class="d-inline-flex align-items-center">
                    Show&nbsp;
                    <b-form-select
                      v-model="perPage"
                      size="sm"
                      :options="pageOptions"
                    ></b-form-select
                    >&nbsp;entries
                  </label>
                </div>
              </div>
              <!-- Search -->
              <div class="col-sm-12 col-md-6">
                <div
                  id="tickets-table_filter"
                  class="dataTables_filter text-md-right"
                >
                  <label class="d-inline-flex align-items-center">
                    Search:
                    <b-form-input
                      v-model="filter"
                      type="search"
                      placeholder="Search..."
                      class="form-control form-control-sm ml-2"
                    ></b-form-input>
                  </label>
                </div>
              </div>
              <!-- End search -->
            </div>
            <!-- Table -->
            <div class="table-responsive mb-0">
              <b-table
                :items="employees"
                :fields="fields"
                responsive="sm"
                :per-page="perPage"
                :current-page="currentPage"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :filter="filter"
                :filter-included-fields="filterOn"
                @filtered="onFiltered"
              >
                <template v-slot:cell(Edit)="row">
                  <button
                    @click="getData(row.item.id)"
                    type="button"
                    data-toggle="modal"
                    data-target="#editServiceModalCenter"
                    class="btn btn-success"
                  >
                    <i class="fa fa-pen" aria-hidden="true" title="Edit"></i>
                  </button>

                  <!-- Modal -->
                  <div
                    class="modal fade"
                    id="editServiceModalCenter"
                    tabindex="-1"
                    role="dialog"
                    aria-labelledby="editServiceModalCenterTitle"
                    aria-hidden="true"
                  >
                    <div
                      class="modal-dialog modal-dialog-centered"
                      role="document"
                    >
                      <div class="modal-content">
                        <div class="modal-header">
                          <h5
                            class="modal-title"
                            id="editServiceModalLongTitle"
                          >
                            Edit Employees Data
                          </h5>
                          <button
                            type="button"
                            class="close"
                            data-dismiss="modal"
                            aria-label="Close"
                          >
                            <span aria-hidden="true">&times;</span>
                          </button>
                        </div>
                        <div class="modal-body">
                          <div class="form-group">
                            <label for="fullname">first Name</label>
                            <input
                              type="text"
                              :value="emp.firstName"
                              @change="updateFirstname"
                              class="form-control"
                            />
                          </div>
                          <div class="form-group">
                            <label for="fullname">Last Name</label>
                            <input
                              type="text"
                              :value="emp.lastName"
                              @change="updateLastname"
                              class="form-control"
                            />
                          </div>
                          <div class="form-group">
                            <label for="fullname">Email</label>
                            <input
                              type="text"
                              :value="emp.email"
                              @change="updateEmai"
                              class="form-control"
                            />
                          </div>

                          <div class="form-group">
                            <label for="fullname">Phone</label>
                            <input
                              type="text"
                              :value="emp.phoneNumber"
                              @change="updatePhone"
                              class="form-control"
                            />
                          </div>

                          <div class="form-group ml-4 form-check-inline">
                            <input
                              type="checkbox"
                              class="form-check-input"
                              :value="emp.isActive == true ? false : true"
                              :checked="emp.isActive"
                              @change="onCheckboxChange"
                            />
                            <label class="form-check-label">Is Active</label>
                          </div>
                        </div>
                        <div class="modal-footer">
                          <button
                            type="button"
                            class="btn btn-secondary"
                            data-dismiss="modal"
                          >
                            Close
                          </button>
                          <button
                            @click="SaveChanges"
                            data-dismiss="modal"
                            type="button"
                            class="btn btn-primary"
                          >
                            <b-spinner
                              v-if="loadingAction"
                              type="grow"
                            ></b-spinner>
                            <span v-else>Save changes</span>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </template>

                <template v-slot:cell(Employee)="row">
                  {{ row.item.firstName + " " + row.item.lastName }}
                </template>
              </b-table>
            </div>
            <div class="flex justify-center">
              <b-spinner
                v-if="loading"
                style="width: 3rem; height: 3rem;"
                label="Large Spinner"
              ></b-spinner>
            </div>
            <div class="row">
              <div class="col">
                <div
                  class="dataTables_paginate paging_simple_numbers float-right"
                >
                  <ul class="pagination pagination-rounded mb-0">
                    <!-- pagination -->
                    <b-pagination
                      v-model="currentPage"
                      :total-rows="rows"
                      :per-page="perPage"
                    ></b-pagination>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>

<script>
import Layout from "@/views/layouts/main.vue";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";

/**
 * Advanced table component
 */
export default {
  page: {
    title: "Manage Employees",
    meta: [{ name: "description", content: appConfig.description }]
  },
  components: { Layout, PageHeader },
  data() {
    return {
      title: "All Employees",
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: [],
      sortBy: "age",
      sortDesc: false,
      fields: [
        { key: "Employee", label: "Employee Name", sortable: true },
        { key: "email", sortable: true },
        { key: "isActive" },
        { key: "phoneNumber" },
        { key: "Edit" }
      ],
      emp: {
        isActive: false
      },
      loading: false,
      loadingAction: false
    };
  },
  computed: {
    employees() {
      return this.$store.getters["manageusers/getAllEmp"];
    },
    /**
     * Total no. of records
     */
    rows() {
      return this.employees && this.employees.length;
    }
  },
  mounted() {
    this.getAllEmployees();
    // Set the initial number of items
    this.totalRows = this.employees.length;
  },
  methods: {
    updateFirstname(e) {
      this.$store.dispatch("manageusers/UpdateFirstName", e.target.value);
    },
    updateLastname(e) {
      this.$store.dispatch("manageusers/UpdateLastName", e.target.value);
    },
    updateEmai(e) {
      this.$store.dispatch("manageusers/UpdateEmail", e.target.value);
    },
    updatePhone(e) {
      this.$store.dispatch("manageusers/UpdatePhone", e.target.value);
    },
    onCheckboxChange(e) {
      this.$store.dispatch("manageusers/CheckboxChange", e.target.value);
    },

    SaveChanges() {
      const data = {
        firstname:
          this.$store.state.manageusers.firstName || this.emp.firstName,
        lastname: this.$store.state.manageusers.lastName || this.emp.lastName,
        phonenumber:
          this.$store.state.manageusers.phone || this.emp.phoneNumber,
        email: this.$store.state.manageusers.email || this.emp.email,
        isactive:
          this.$store.state.manageusers.CheckboxChange || this.emp.isActive,
        guid: this.emp.guid,
        timeStamp: this.emp.timeStamp
      };

      this.loadingAction = true;
      this.$store
        .dispatch("manageusers/UpdateEmpData", data)
        .then((response) => {
          if (response.status == 200) {
            this.loadingAction = false;
            this.$swal({
              icon: "success",
              title: "success",
              text: "Employee records updated successfully"
            });
            location.reload();

            this.getAllEmployees();
          }
        })
        .catch((err) => {
          this.loadingAction = false;
          err.response.data.map((el) => {
            this.$swal({
              icon: "error",
              title: "Oops...",
              text: el.error
            });
          });
        });
    },

    /**
     * Search the table data with search input
     */
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },

    getData(id) {
      this.employees.forEach((data) => {
        if (data.id === id) {
          this.emp = data;
        }
      });
    },

    getAllEmployees() {
      this.loading = true;
      this.$store.dispatch("manageusers/getAllEmployees");
      this.loading = false;
    }
  }
};
</script>
